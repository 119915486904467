import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import TwoColDisplay from "../../components/two-col-display"

const SamPage = () => {
  const query = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "profile-sam" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const node = query.allFile.edges[0].node
  const image = node.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="Sam" />
      <div className="grid grid-cols-1 items-center justify-center w-full">
        <div className="pt-4 flex flex-grow justify-center items-center">
          <div className="max-w-4xl">
            <TwoColDisplay image={image}>
              <div className="text-center">
                <a href="https://instagram.com/samfransiscotattoo">
                  <h2 className="font-rubik text-xl no-underline text-black">
                    @samfransiscotattoo
                  </h2>
                </a>
                <div className="px-8 grid grid-cols-1 gap-4 py-4">
                  <div>
                    I’m Sam and I’m a licensed ink slinger at Sterling Tattoo
                    Club. I enjoy art, live music and mopping tattoo shop
                    floors.
                  </div>
                </div>
              </div>
            </TwoColDisplay>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SamPage
